import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Switch,
  useColorMode,
  useColorModeValue,
  Box,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLoginUserMutation } from "../../graphql/graphqlSchema";

const Login = () => {
  const { colorMode, setColorMode } = useColorMode();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const Toast = useToast();
  const formBackground = useColorModeValue("gray.100", "gray.700");
  const { t } = useTranslation("app");
  const [loginUser, { loading, called }] = useLoginUserMutation({
    onCompleted: (data) => {
      if (data.loginUser?.errors!.length == 0) {
        window.location.href = data.loginUser?.redirectPath!;
      } else {
        data.loginUser?.errors?.map((error) => {
          error?.messages!.map((message) => {
            Toast({
              title: "Error",
              description: message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          });
        });
      }
    },
    onError: (error) => {
      Toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const handleLogin = (e) => {
    e.preventDefault();
    loginUser({
      variables: {
        email,
        password,
        rememberMe,
      },
    });
  };

  const bgGradient = useColorModeValue(
    [
      "linear(to-tr, teal.300, yellow.400)",
      "linear(to-b, whiteAlpha.100, purple.300)",
    ],
    [
      "linear(to-tr, blue.300, blue.400)",
      "linear(to-b, blackAlpha.300, green.700)",
    ]
  );

  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      bgGradient={bgGradient}
    >
      <Box
        // style={{ backdropFilter: "blur(5px)" }}
        bg={formBackground}
        borderRadius={8}
        boxShadow="lg"
        maxW="400px"
        w="full"
      >
        <form onSubmit={handleLogin}>
          <Flex flexDirection="column" p={12}>
            <Heading mb={6}>{t("login.index.login_title")}</Heading>
            <FormControl mb={3} isRequired>
              <FormLabel htmlFor="email">{t("login.index.email")}</FormLabel>
              <Input
                id="email"
                name="email"
                placeholder={t("login.index.email") || ""}
                type="email"
                variant="filled"
                size="lg"
                bgColor={useColorModeValue("white", "gray.800")}
                w="full"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl mb={6} isRequired>
              <FormLabel htmlFor="password">
                {t("login.index.password")}
              </FormLabel>
              <Input
                id="password"
                name="password"
                placeholder={t("login.index.password") || ""}
                type="password"
                variant="filled"
                size="lg"
                bgColor={useColorModeValue("white", "gray.800")}
                w="full"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            <Button
              type="submit"
              colorScheme="teal"
              mb={8}
              isLoading={loading && called}
            >
              {t("login.index.login_button")}
            </Button>
            <Flex width="full" justify="space-evenly" gap="2">
              <Button colorScheme="teal" mb={8} variant="link">
                {t("login.index.register_button")}
              </Button>

              <Button
                colorScheme="teal"
                mb={8}
                variant="link"
                onClick={() => (window.location.href = "/users/password/new")}
              >
                {t("login.index.forgot_password_button")}
              </Button>
            </Flex>
            <HStack>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="dark_mode" mb="0">
                  {t("login.index.dark_mode")}
                </FormLabel>
                <Switch
                  id="dark_mode"
                  isChecked={colorMode === "dark" ? true : false}
                  colorScheme="teal"
                  size="lg"
                  onChange={() =>
                    setColorMode(colorMode === "dark" ? "light" : "dark")
                  }
                />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="rememberMe" mb="0">
                  {t("login.index.rememberMe")}
                </FormLabel>
                <Switch
                  id="rememberMe"
                  isChecked={rememberMe}
                  colorScheme="teal"
                  size="lg"
                  onChange={() => setRememberMe((remembered) => !remembered)}
                />
              </FormControl>
            </HStack>
          </Flex>
        </form>
      </Box>
    </Flex>
  );
};

export default Login;
