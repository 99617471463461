import React from "react";
import { createRoot } from "react-dom/client";
import { MessagesProvider } from "@pinkairship/use-messenger";
import "../src/i18n";
import Main from "../src/Login";
import ErrorBoundary from "../src/components/ErrorBoundary";
import ApolloCustomProvider from "../src/graphql/ApolloCustomProvider";
import ChakraProvider, { UserAppearance } from "../src/hooks/ChakraProvider";
import { TrackerTheme } from "../src/themes";
import { Box } from "@chakra-ui/react";

const DEFAULT_APPEARANCE: UserAppearance = {
  theme: TrackerTheme.default,
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <ApolloCustomProvider>
    <ChakraProvider appearance={DEFAULT_APPEARANCE}>
      <MessagesProvider>
        <ErrorBoundary>
          <Box w="vw" h="vh">
            <Main />
          </Box>
        </ErrorBoundary>
      </MessagesProvider>
    </ChakraProvider>
  </ApolloCustomProvider>
);
